/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
if(window.innerWidth <= 767){ $('#input_1_1').attr('placeholder', 'Your Name');
              $('#input_1_2').attr('placeholder', 'Your Email');

            }


	      // $(function() {

        /*
        * alm_adv_filter()
        * https://connekthq.com/plugins/ajax-load-more/examples/filtering/advanced-filtering/
        *
        */

        var alm_is_animating = false;

        function alm_adv_filter(element){

          // var allCheckbox = $('.advanced-filter-menu .all input').prop('checked');

          console.log('element', $(element.target).val());

          if($(element.target).val() == "all"){
              $('.advanced-filter-menu .other-categories input:checked').each(function(){
                var el = $(this);
                el.prop('checked',false);
                
              });
              $('.advanced-filter-menu .all input').prop('checked', true);
          }

             var otherCheckboxes = [];

             //deselect All button
            $('.advanced-filter-menu .other-categories input:checked').each(function(){
                var el = $(this);
                otherCheckboxes.push(el.val());
                 console.log('t1', el.val());
                // $('.advanced-filter-menu .all input').prop('checked', false);
              });

              if( otherCheckboxes.length > 0 ){
                $('.advanced-filter-menu .all input').prop('checked', false);
              }else{
                $('.advanced-filter-menu .all input').prop('checked', true);
                allCheckbox = true;

              }


          if(alm_is_animating){
            return false; // Exit if filtering is still active
          }

          alm_is_animating = true;

          var obj= {},
             count = 0;

          // Loop each filter menu
          $('.advanced-filter-menu').each(function(e){
            var menu = $(this),
                type = menu.data('type'), // type of filter elements (checkbox/radio/select)
                parameter = menu.data('parameter'), // category/tag/year
                value = '',
                count = 0;

            // Loop each item in the menu
            if(type === 'checkbox' || type === 'radio'){ // Checkbox or Radio
              $('.other-categories input:checked', menu).each(function(){
                count++;
                var el = $(this);

                // Build comma separated string of items
                if(count > 1){
                  value += ','+el.val();
                }else{
                  value += el.val();
                }
              });
            }

            if(type === 'select'){ // Select
              var select = $(this);
              value += select.val();
            }

            obj[parameter] = value; // add value(s) to obj

          });

          // console.log(obj);

          var data = obj;
          
          $.fn.almFilter('fade', '300', data); // Send data to Ajax Load More
        }

        $('.tag-checkbox').on('click', alm_adv_filter); // 'Apply Filter' button click



        /*
        * almFilterComplete()
        * Callback function sent from core Ajax Load More
        *
        */
        $.fn.almFilterComplete = function(){
          alm_is_animating = false; // clear animating flag
        };

      // })(jQuery);



        $('.product-link').on('click', function(e){
          e.preventDefault();
        });
        //
        $('li.menu-item').on(
          {
            mouseenter: function(e){
                console.log(' mouse in e', e);
                console.log(' mouse in $(e.target)');
                $(e.target.closest('li.menu-item')).addClass('sub-menu-active');
                // $(e.target).addClass('sub-menu-active');
              },
            mouseleave: function(e){
                console.log(' mouse out e', e);
                $(e.target.closest('li.menu-item')).removeClass('sub-menu-active');
              }
        });

        // $('li.menu-item.sub-menu-active li').on('click', function(e){
        //   console.log(' mouse in click e', e);
        //
        //   $(e.target.closest('li.sub-menu-active')).removeClass('sub-menu-active');
        //
        // });

        function scroll_detect(){

            if(window.pageYOffset > 50){
              // console.log('test');

              //console.log('sticky');
                $('header').addClass('scroll-active');
                //  $('header .shoping-bag-container').css('top', '70px');
            }else{
              if($('body').hasClass('page-id-8')){
                // console.log('inside the pag-id-8');
                if(!$('.search-slider-wrapper').hasClass('active') ){
                  // console.log('inside the removeClass');
                  $('header').removeClass('scroll-active');
                }else{
                  // console.log('inside the addClass');
                  $('header').removeClass('add-active');

                }


              }else{
                $('header').addClass('scroll-active');
              }
            }

            //set automatic paddings
            // console.log('test');
            // console.log($('.main-header-section > .container').css('margin-right'));
            var rightMargin = parseInt($('footer .container').css('margin-right')) +15;
            var leftMargin = parseInt($('footer .container').css('margin-left')) +15;
            // console.log("leftMargin", leftMargin+15);
            // console.log("rightMargin", rightMargin+15);
            $('.automatic-left-padding > .vc_column-inner').css('padding-left', leftMargin);
            $('.automatic-right-padding > .vc_column-inner').css('padding-right', rightMargin);
            $('.custom-automatic-padding').css('padding-right', leftMargin);// right margin doesn't work on firefox
            // console.log('right padding', leftMargin);



            if(window.innerWidth <= 767){
              $('.automatic-left-padding > .vc_column-inner').css('padding-right', rightMargin);
              $('.automatic-right-padding > .vc_column-inner').css('padding-left', leftMargin);
              $('.custom-automatic-padding').css('left-right', leftMargin);
              $('.custom-automatic-padding').css('padding-right', leftMargin);// right margin doesn't work on firefox

              // console.log('leftMargin', leftMargin);
              if(rightMargin === '0px'){
                $('.automatic-left-padding > .vc_column-inner').css('padding-right', '15px');
                $('.automatic-left-padding > .vc_column-inner').css('padding-left', '15px');
              }
              if(leftMargin === '0px'){
                $('.automatic-right-padding > .vc_column-inner').css('padding-left', '15px');
                $('.automatic-right-padding > .vc_column-inner').css('padding-right', '15px');
                $('.custom-automatic-padding').css('padding-right', '15px');
                $('.custom-automatic-padding').css('padding-left', '15px');

              }


              // $(window).on('scroll', function() {
                  var scrollTop = $(this).scrollTop();
                  var elementTop = $('#intro-row').offset().top;
                  if ( (elementTop-38) < scrollTop ) {
                      // alert( $(this).text() + ' was scrolled to the top' );
                      $('header').addClass('mobile-sticky');
                  }else{
                    $('header').removeClass('mobile-sticky');

                  }
                  // $('#intro-row').each(function() {
                  //     var topDistance = $(this).offset().top;
                  //
                  //     if ( (topDistance+100) < scrollTop ) {
                  //         alert( $(this).text() + ' was scrolled to the top' );
                  //     }
                  // });
              // });

              $('#input_1_1').attr('placeholder', 'Your Name');
              $('#input_1_2').attr('placeholder', 'Your Email');

            }else{
              $('#input_1_1').attr('placeholder', '');
              $('#input_1_2').attr('placeholder', '');
              $('.automatic-left-padding > .vc_column-inner').css('padding-right', '50px');
              $('.automatic-right-padding > .vc_column-inner').css('padding-left', '50px');


              var scrollTop = $(this).scrollTop();
              var elementTop = ( $('#intro-row').length >0 )?$('#intro-row').offset().top: 0;
              if ( (elementTop-38) < scrollTop ) {
                  // alert( $(this).text() + ' was scrolled to the top' );
                  $('header').addClass('desktop-sticky');
              }else{
                $('header').removeClass('desktop-sticky');

              }
            }




            //set automatic bg padding

            var leftPosition = parseInt(window.innerWidth) - parseInt(rightMargin) - 359;//width of the background;
            // console.log(leftPosition);
              $('.automatic-bg-postition').css('background-position', leftPosition+'px 181px');




          //$('.wrap.container').css('margin-bottom', $('footer').css('height'));

        }
        setInterval(scroll_detect, 100);


        $('.products .single-product').on('hover', function(){
            // $(".slider").slider('value', 50);
            var childrens =   $('.products.scroll .single-product');

            // console.log(childrens);
            // console.log('this',this);

            if(childrens[0] === this){
              // console.log('1st child');
              $(".slider").slider('value',15);


            }else if(childrens[1] === this){
              // console.log('2nd child');
              $(".slider").slider('value',30);

            }else if(childrens[2] === this){
              // console.log('3rd child');
              $(".slider").slider('value',85);

            }else if(childrens[3] === this){
              // console.log('3rd child');
              $(".slider").slider('value',95);

            }else if(childrens[4] === this){
              // console.log('3rd child');
              $(".slider").slider('value',5);


            }

        });




        //smooth scrolling

        // Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        // product slider
        function closest(number, array){
          curr = array[0];

          for (var val=0; val <array.length ; val++ ){
            if (abs (num - val) < abs (num - curr)) {
              curr = val;
            }
          }

          return curr;
        }



        var slider = $(".slider").slider({
            value: 25,
            // step: 25,
            min: 0,
            max: 100,
            animate: true,
            slide: function(event,ui){
              var sliderValues = [15, 30, 85, 95, 5];

              function closest(num, arr){
                curr = arr[0];
                var diff = Math.abs (num - curr);

                for (var val=0; val <arr.length ; val++ ){
                  var newdiff = Math.abs (num - arr[val]);

                  if (newdiff < diff) {
                    diff = newdiff;
                    curr = arr[val];
                  }
                }

                return curr;


              }


              $($('.products.pure .single-product .product-image')).removeClass('hover-active');

              if(ui.value < 15){
                $(".slider").slider('value', 15);
                $($('.products.pure .single-product:nth-of-type(1) .product-image')).addClass('hover-active');

                // $($('.products.pure .single-product')[0]).addClass('hover-active');
                return false;
              }else if(ui.value > 85){
                $(".slider").slider('value', 85);
                $($('.products.pure .single-product:nth-of-type(3) .product-image')).addClass('hover-active');

                // $($('.products.pure .single-product')[2]).addClass('hover-active');

                return false;
              }else{
                // console.log('ui',ui.value);
                // console.log('array',sliderValues);
                // console.log('closest',closest(ui.value, sliderValues));

                $(".slider").slider('value', closest(ui.value, sliderValues));
                switch (closest(ui.value, sliderValues)) {
                  case 15:
                    $($('.products.pure .single-product:nth-of-type(1) .product-image')).addClass('hover-active');
                    break;
                  case 30:
                    $($('.products.pure .single-product:nth-of-type(2) .product-image')).addClass('hover-active');
                    break;
                  case 85:
                    $($('.products.pure .single-product:nth-of-type(3) .product-image')).addClass('hover-active');
                    break;
                  case 95:
                      $($('.products.pure .single-product:nth-of-type(4) .product-image')).addClass('hover-active');
                      break;
                  case 15:
                      $($('.products.pure .single-product:nth-of-type(5) .product-image')).addClass('hover-active');
                      break;
                  default:

                }
                return false;
              }

            }

          });


          $('.products.pure .single-product').on('hover',function(){
            $($('.products.pure .single-product .product-image')).removeClass('hover-active');

          });

          // $('.product-image').on('hover',
          // function(){
          //     anime({
          //       targets: '.product-image img',
          //       scale:[1, 1.4],
          //       duration:200
          //     })
          // },
          // function(){
          //   anime({
          //     targets: '.product-image img',
          //     scale:[1.4, 1],
          //     duration:200
          //   })
          // })



          // button animation
          var buttonEl = $('.button');
          var gfromfooter = $('.gform_footer');

          function animateButton(scale, duration, elasticity) {
            anime.remove(buttonEl);
            anime({
              targets: '.button, .gform_footer',
              scale: scale,
              duration: duration,
              elasticity: elasticity
            });
          }

          function enterButton() { animateButton(1.2, 800, 400) };
          function leaveButton() { animateButton(1.0, 600, 300) };

          buttonEl.on('hover', enterButton, leaveButton);
          // buttonEl.on('mouseleave', leaveButton, false);


          var productDetails ;


          // $('.more-button-section .button').on('click',function(e){
          //   e.preventDefault();



          //   var product = $(this).data('product');



          //   console.log(product);
          //   $('.menu').css('visibility', 'visible');



          // anime({
          //           targets: '.single-product-details',
          //           height: 0,
          //           width: 0,
          //           opacity:0,
          //           duration: 0,
          //           elasticity: 0
          //         });

          // anime({
          //           targets: '#prdouct-details-'+product,
          //           height: '100%',
          //           width:'100vw',
          //           opacity:1,
          //           duration: 700,
          //           elasticity: 300
          //         });



          //                     var scroll = {
          //                       y: window.pageYOffset
          //                     };

          //                     var top = $('#prdouct-details-'+product).offset().top;
          //                     console.log('top',top);
          //                     console.log('pageYOffset', window.pageYOffset);
          //                     // anime({
          //                     //    targets: scroll,
          //                     //    y: ((top + window.pageYOffset)),
          //                     //    duration: 350,
          //                     //    easing: 'easeInOutCubic',
          //                     //    update: () => window.scroll(0, scroll.y)
          //                     //  });

          //                     $('html, body').animate({
          //                          scrollTop: $('#prdouct-details-'+product).offset().top
          //                      }, 200);




          // });


          /*$('.single-product-wrapper .product-image').on('click',function(e){
            e.preventDefault();



            var product = $(this).data('product');



            console.log(product);
            $('.menu').css('visibility', 'visible');



          anime({
                    targets: '.single-product-details',
                    height: 0,
                    width: 0,
                    opacity:0,
                    duration: 0,
                    elasticity: 0
                  });

          anime({
                    targets: '#prdouct-details-'+product,
                    height: '100%',
                    width:'100vw',
                    opacity:1,
                    duration: 700,
                    elasticity: 300
                  });



                              var scroll = {
                                y: window.pageYOffset
                              };

                              var top = $('#prdouct-details-'+product).offset().top;
                              console.log('top',top);
                              console.log('pageYOffset', window.pageYOffset);
                              // anime({
                              //    targets: scroll,
                              //    y: ((top + window.pageYOffset)),
                              //    duration: 350,
                              //    easing: 'easeInOutCubic',
                              //    update: () => window.scroll(0, scroll.y)
                              //  });

                              $('html, body').animate({
                                   scrollTop: $('#prdouct-details-'+product).offset().top
                               }, 200);




          });*/ 



        $('.product-details .close').on('click', function(e){
          console.log('close12');
          anime({
                    targets: '.single-product-details',
                    height: 0,
                    width: 0,
                    opacity:0,
                    duration: 600,
                    elasticity: 300
                  });
        });


        $('.navigation-section .left').on('click', function(){

          var firstProduct = $($('.products.pure .single-product')[0]).data('productid');
          var lastProduct = $($('.products.pure .single-product')[2]).data('productid');
          var previousProduct = $(this).data('previous');
          var current = $(this).data('current');


          console.log('first', firstProduct);
          console.log('last', lastProduct);
          console.log('previous', previousProduct);
          console.log('current', current);


          if(previousProduct!== ""){




            anime({
                      targets: '#prdouct-details-'+previousProduct,
                      height: '100%',
                      width:'100vw',
                      opacity:1,
                      duration: 0
                    });


                    anime({
                              targets: '#prdouct-details-'+current,
                              height: 0,
                              width: 0,
                              opacity:0,
                              duration: 0
                            });




                    anime({
                              targets: '#prdouct-details-'+previousProduct+' .product-image-wrapper img',
                              translateY:[-800,0],
                              opacity:[0,1],
                              elasticity: 0,

                              duration: 800
                            });
                            anime({
                                      targets: '#prdouct-details-'+previousProduct+' .details-section .custom-automatic-padding',
                                      translateY:[800,0],
                                      opacity:[0,1],
                                      elasticity: 0,

                                      duration: 800
                                    });

          }else{

            console.log('previous ELSE');


            anime({
                      targets: '.single-product-details',
                      height: 0,
                      width: 0,
                      opacity:0,
                      duration: 0
                    });

            anime({
                      targets: '#prdouct-details-'+lastProduct,
                      height: '100%',
                      width:'100vw',
                      opacity:1,
                      duration: 0
                    });


              anime({
                        targets: '#prdouct-details-'+lastProduct+' .product-image-wrapper img',
                        translateY:[-800,0],
                        opacity:[0,1],
                        elasticity: 0,

                        duration: 800
                      });
                      anime({
                                targets: '#prdouct-details-'+lastProduct+' .details-section .custom-automatic-padding',
                                translateY:[800,0],
                                opacity:[0,1],
                                elasticity: 0,

                                duration: 800
                              });

          }

        });

        $('.navigation-section .right').on('click', function(){

          var firstProduct = $($('.products.pure .single-product')[0]).data('productid');
          var lastProduct = $($('.products.pure .single-product')[2]).data('productid');
          var nextProduct = $(this).data('next');


          console.log('first', firstProduct);
          console.log('last', lastProduct);
          console.log('next', nextProduct);


          if(nextProduct!== ""){


          //   anime({
          //           targets: '.single-product-details',
          //           height: 0,
          //           width: 0,
          //           opacity:0,
          //           duration: 0,
          //           elasticity: 0
          //         });
          //
          // anime({
          //           targets: '#prdouct-details-'+product,
          //           height: '100%',
          //           width:'100vw',
          //           opacity:1,
          //           duration: 700,
          //           elasticity: 300
          //         });


                  anime({
                            targets: '.single-product-details',
                            height: 0,
                            width: 0,
                            opacity:0,
                            duration: 0
                          });

                  anime({
                            targets: '#prdouct-details-'+nextProduct,
                            height: '100%',
                            width:'100vw',
                            opacity:1,
                            duration: 0
                          });



            // anime({
            //           targets: '.single-product-details',
            //           height: 0,
            //           width: 0,
            //           opacity:0,
            //           duration: 0,
            //           elasticity: 0
            //         });

            anime({
                      targets: '#prdouct-details-'+nextProduct,
                      height: '100%',
                      width:'100vw',
                      opacity:1,
                      duration: 0,
                      elasticity: 0
                    });

                    anime({
                              targets: '#prdouct-details-'+nextProduct+' .product-image-wrapper img',
                              translateY:[800,0],
                              opacity:[0,1],
                              elasticity: 0,
                              duration: 800
                            });
                            anime({
                                      targets: '#prdouct-details-'+nextProduct+' .details-section .custom-automatic-padding',
                                      translateY:[-800,0],
                                      opacity:[0,1],
                                      elasticity: 0,
                                      duration: 800
                                    });

          }else{

            console.log('next ELSE');

            anime({
                      targets: '.single-product-details',
                      height: 0,
                      width: 0,
                      opacity:0,
                      duration: 0,
                      elasticity: 0
                    });

            anime({
                      targets: '#prdouct-details-'+firstProduct,
                      height: '100%',
                      width:'100vw',
                      opacity:1,
                      duration: 0,
                      elasticity: 0
                    });

                    anime({
                              targets: '#prdouct-details-'+firstProduct+' .product-image-wrapper img',
                              translateY:[800,0],
                              opacity:[0,1],
                              elasticity: 0,

                              duration: 800
                            });
                            anime({
                                      targets: '#prdouct-details-'+firstProduct+' .details-section .custom-automatic-padding',
                                      translateY:[-800,0],
                                      opacity:[0,1],
                                      elasticity: 0,

                                      duration: 800
                                    });

          }

        });






        $(document).bind('scroll', function(ev) {
        var scrollOffset = $(document).scrollTop();
        var containerOffset = ( $('.intro-section').length >0 )? $('.intro-section').offset().top - window.innerHeight : null;
        if (scrollOffset > containerOffset) {
          anime.timeline()
            .add({
              targets: '.animation-word',
              opacity: [0,1],
              easing: "easeInOutQuad",
              duration: 1060,
              delay: function(el, i) {
                return 40 * (i+1)
              }
            }).add({
              targets: '.apple-in-a-bottle-image img',
              opacity:[0,1],
              scale:2,
              duration: 500
            })


            ;
					 // unbind event
            $(document).unbind('scroll');
        }
    });

    var played = false;
    $(window).scroll(function() {
       if($(window).scrollTop() + $(window).height() >= $(document).height()-100) {
           console.log('bootom');
           if(!played){

             played = true;
             anime.timeline()
                  .add({
                    targets: '.footer-logo img',
                    opacity:[0,1],
                    scale:[0,1],
                    duration: 1000
                  })

           }

       }
    });

    // var tap_icons_played = false
    // $(window).scroll(function() {
    //   var containerOffsettap = $('#tap-icons').offset().top;
    //
    //    if($(window).scrollTop() + $(window).height()*1.1 >= containerOffsettap) {
    //        console.log('correct');
    //        if(!tap_icons_played){
    //
    //          tap_icons_played = true;
    //          var duration1 = 1000;
    //          var translateXDistance = 110;
    //          var translateYDistance = 110;
    //          anime.timeline()
    //              .add({
    //                targets:'#tap-icons #Sunshine',
    //                opacity:[0,1],
    //                // scale: [0,1],
    //                translateX:0,
    //                duration: duration1
    //              }).add({
    //                targets:'#tap-icons #Hand-Picked',
    //                opacity:[0,1],
    //                // scale: [0,1],
    //                // translateX:translateXDistance*1,
    //
    //                duration: duration1
    //              }).add({
    //                targets:'#tap-icons #Cold-Pressed',
    //                opacity:[0,1],
    //                // scale: [0,1],
    //                // translateX:translateXDistance*2,
    //                duration: duration1
    //              }).add({
    //                targets:'#tap-icons #Trace',
    //                opacity:[0,1],
    //                // scale: [0,1],
    //                // translateX:translateXDistance*0,
    //                // translateY:translateYDistance*1,
    //                duration: duration1
    //              }).add({
    //                targets:'#tap-icons #NO-Pres',
    //                opacity:[0,1],
    //                // scale: [0,1],
    //                // translateX:translateXDistance*1,
    //                // translateY:translateYDistance*1,
    //                duration: duration1
    //              }).add({
    //                targets:'#tap-icons #Sus-Sourced',
    //                opacity:[0,1],
    //                // scale: [0,1],
    //                // translateX:translateXDistance*2,
    //                // translateY:translateYDistance*1,
    //                duration: duration1
    //              });
    //
    //        }
    //
    //    }
    // });


    var tap_icons_played = false;
    $(document).bind('scroll', function(ev) {
    var scrollOffsettap = $(document).scrollTop();
( $('#intro-row').length >0 )
    var containerOffsettap = (( $('#tap-icons').length >0 ))? $('#tap-icons').offset().top: null;
    console.log("beffore CORRECT");
    console.log("scrollOffsettap", scrollOffsettap);
    console.log("scrollOffsettap", scrollOffsettap);

    if (scrollOffsettap > containerOffsettap) {
      console.log("CORRECT");

       // unbind event
        // $(document).unbind('scroll');
    }
});


      setInterval(function(){
        var element_1 = $('.mobile-products-for-tart-meter .owl-pagination .owl-page:nth-of-type(1)').hasClass('active');
        var element_2 = $('.mobile-products-for-tart-meter .owl-pagination .owl-page:nth-of-type(2)').hasClass('active');
        var element_3 = $('.mobile-products-for-tart-meter .owl-pagination .owl-page:nth-of-type(3)').hasClass('active');

        if(element_1){
          $(".slider").slider('value', 15);
        }else if(element_2){
          $(".slider").slider('value', 30);

        }else if(element_3){
          $(".slider").slider('value', 85);

        }

      },400);

      }

    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'our_story':{
      init:function(){
        centerLine();

        function centerLine() {
          $("#center_line").height($("#my-container").height());
          $("#center_line").offset({left: $("body").width() / 2 - 1});
        }
      },
      finalize: function(){

        jQuery(document).ready(function($){
          //scroll effect
            $(window).scroll(function() {
              var pulseBallElementRect = document
                .getElementById("pulse_ball")
                .getBoundingClientRect();

            var lineElementRect = document
              .getElementById("center_line")
              .getBoundingClientRect();
              
              var line = $("#center_line");
              var diff = window.innerHeight / 2 - pulseBallElementRect.top;

              
            if (diff >= 0) {
                if (lineElementRect.bottom -100 <  window.innerHeight/2) {
                  //out of the line boundary
                  // console.log('out of the line boundary');
                  jQuery("#pulse_ball").css("position", "absolute");
                  // jQuery("#pulse_ball").css("top", "unset");
                  jQuery("#pulse_ball").css("top", $(line).height() -100);
                  jQuery("#pulse_ball").addClass("custom-left");
                } else {
                  if( (pulseBallElementRect.top - lineElementRect.top ) < 100 ){
                    // console.log('Top of the LINE, ball needs to stop');
                    jQuery("#pulse_ball").css("position", "absolute");
                    jQuery("#pulse_ball").css("top",   100 + diff);
                    jQuery("#pulse_ball").addClass("custom-left");

                  }else{
                    // console.log("STARTED SCROLLING", lineElementRect.top);
                    jQuery("#pulse_ball").removeClass("custom-left");
                    jQuery("#pulse_ball").css("position", "fixed");
                    jQuery("#pulse_ball").css("top", window.innerHeight / 2);
                  }
                }



              } else {
                // jQuery("#pulse_ball").addClass("custom-left");
                // jQuery("#pulse_ball").css("position", "absolute");
                // $("#pulse_ball").css("top", 100);
              }




            });
            });


      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
